import Script from 'next/script';
import { url } from 'config/env';

// utils
import { getServerVars } from 'utils/get-server-vars';

export const LEAD_MANAGEMENT_GTM_KEY = 'GTM-TLCTR6XJ';

export default async function ScriptsServer({ globals, headersList, slug }) {
  const server = getServerVars(headersList);
  const gtmUrl = url(server?.gtmUrl ?? 'https://serversidegtm.prod.web.geberit.com');
  const appStage = server?.appStage ?? 'dev';

  const isDev = appStage.includes('dev');
  const isQa = appStage.includes('qa');
  const isProd = !isDev && !isQa;
  const currentUrl = `${server.baseUrl}/${slug}`;

  // due to the landingpage traumbad on ch should have a different GTM container we need to check the pathname
  // this can be removed in half a year
  const leadManagementLandingUrls = [
    'https://www.geberit.ch/landingpages-de/traumbad/',
    'https://www.geberit.ch/landingpages-de/traumbad-tools/',
    'https://www.geberit.ch/landingpages-de/traumbad',
    'https://www.geberit.ch/landingpages-de/traumbad-tools',
  ];
  const isLeadManagementLanding = leadManagementLandingUrls.includes(currentUrl ?? '');
  const gtmKey = isLeadManagementLanding ? LEAD_MANAGEMENT_GTM_KEY : globals?.gtmKey;

  return (
    <>
      {/* gtm */}
      {isDev && (
        <Script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.setAttribute('gtm-script','true');j.src=
                '${gtmUrl}/gtm.js?id='+i+dl+ '&gtm_auth=Y51geUNuEXhIBsGRexay-w&gtm_preview=env-189&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${gtmKey}');`,
          }}
          data-gtm-key={gtmKey}
        />
      )}
      {isQa && (
        <Script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.setAttribute('gtm-script','true');j.src=
                '${gtmUrl}/gtm.js?id='+i+dl+ '&gtm_auth=zoa16TpiGUNPR585n3FnJw&gtm_preview=env-345&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${gtmKey}');`,
          }}
          data-gtm-key={gtmKey}
        />
      )}
      {isProd && (
        <Script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `if(window.navigator.userAgent.indexOf(atob('TGlnaHRob3VzZQ==')) === -1){(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.setAttribute('gtm-script','true');j.src=
                '${gtmUrl}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${gtmKey}');}`,
          }}
          data-gtm-key={gtmKey}
        />
      )}
      {/* gtm end */}
    </>
  );
}
