import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { useSelect } from 'downshift';

// gdds components
import { Button, Divider, Tooltip } from '@geberit/gdds';

// components
import { NavButton } from '../common/nav-button';
import { Initials } from './Initials';
import { Translation } from 'components/Translation/Translation';

// contexts
// styles
import { AnimationWrapperIcons, IconContainer, ProfileContainer } from '../navbar/navbar.styles';

// utils
import { useMetaNavigationInfo } from '../../../../utils/use-metanav';
import { useIsTablet } from 'components/App/SizeProvider';
import {
  useLocatorPageOpen,
  useMainNavShown,
  useProfilePageOpen,
  useSearchOpen,
} from '../mega-menu-provider';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useCiamLogin } from 'utils/hooks/useCiam';
import { siteShortcutTrackingAction } from '../../../../trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { HorizontalMarginWrapper } from '../common/common.styles';
import { NavItem } from '../switch/nav-item';
import { ProfileFlyoutWrapper } from './profile-flyout.styles';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';

type ProfileProps = {
  showOnlyIcons: boolean;
  isIcon: boolean;
};

export function Profile({ showOnlyIcons, isIcon }: Readonly<ProfileProps>) {
  const [isProfileFlyoutOpen, setIsProfileFlyoutOpen] = useState(false);
  const { loggedIn, profile, ciamEnabled } = useMetaNavigationInfo();
  const { isSearchOpen } = useSearchOpen();
  const isTablet = useIsTablet({ gdds: true });
  const { isMainNavShown } = useMainNavShown();
  const currentLanguage = useCurrentLanguage();
  const country = currentLanguage?.slice(currentLanguage.length - 2);
  const gigyaWindow = typeof window !== 'undefined' ? window['gigya'] : null;
  const { login } = useCiamLogin(gigyaWindow);
  const { setIsProfilePageOpen } = useProfilePageOpen();
  const { isLocatorPageOpen } = useLocatorPageOpen();
  const track = useTracking();
  const showButtons = isSearchOpen || isLocatorPageOpen;
  const isSticky = !isMainNavShown;
  const router = useRouter();
  const translate = useTranslationFunction();

  const handleTrackedLogin = () => {
    track.trackEvent(siteShortcutTrackingAction('Login'));
    login(currentLanguage, `${country}_WebsitePlatform`);
  };

  const { logout, orderHistoryUrl, webshopEnabled, ciamProfileUrl } = useMetaNavigationInfo();
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);

  const [selectedItem, setSelectedItem] = useState<
    { item: number; action: Function } | null | undefined
  >(null);
  const show = webshopEnabled && !webshopDowntime;

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: show
      ? [
          {
            item: 0,
            action: () => {
              router.push(orderHistoryUrl);
            },
          },
          {
            item: 1,
            action: () => {
              router.push(ciamProfileUrl ?? '');
            },
          },
          {
            item: 2,
            action: logout,
          },
        ]
      : [
          {
            item: 1,
            action: () => {
              router.push(ciamProfileUrl ?? '');
            },
          },
          {
            item: 2,
            action: logout,
          },
        ],
    itemToString: (l) => String(l?.item),
    selectedItem,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      newSelectedItem?.action();
      setSelectedItem(newSelectedItem);
    },
  });

  return (
    ciamEnabled && (
      <ProfileContainer showMobile={showButtons} isActive={isProfileFlyoutOpen}>
        <IconContainer className="user-icon">
          {loggedIn ? (
            <Tooltip
              promptText={translate('mega_menu_tooltip_ciam')}
              initialtooltipPosition="bottom"
              offset={{ bottom: 12 }}
              onClickedActive={false}
              onHoveredActive={showOnlyIcons}
            >
              <NavButton
                leftIconName={loggedIn ? undefined : 'profile'}
                paddingLeft={loggedIn}
                onClick={() => {
                  if (isTablet) {
                    setIsProfilePageOpen(true);
                  } else {
                    setIsProfileFlyoutOpen((state) => !state);
                  }
                }}
                className={isSticky ? undefined : 'initials'}
                {...getToggleButtonProps()}
                aria-label={translate('mega_menu_tooltip_ciam')}
              >
                <Initials
                  initials={`${profile?.firstName.charAt(0)}${profile?.lastName.charAt(0)}`}
                />
                <AnimationWrapperIcons showOnlyIcons={showOnlyIcons}>
                  <span className="label">
                    {profile?.firstName} {profile?.lastName}
                  </span>
                </AnimationWrapperIcons>
              </NavButton>
            </Tooltip>
          ) : (
            <Tooltip
              promptText={translate('mega_menu_tooltip_ciam')}
              initialtooltipPosition="bottom"
              offset={{ bottom: 12 }}
              onClickedActive={false}
              onHoveredActive={showOnlyIcons}
            >
              <Button
                isIcon={isIcon}
                symbol="profile"
                stylingType={isSticky ? 'flat' : 'icon'}
                onClick={handleTrackedLogin}
                aria-label={translate('mega_menu_tooltip_ciam')}
              >
                <AnimationWrapperIcons showOnlyIcons={showOnlyIcons}>
                  <Translation id="mega_menu_login" className="label" />
                </AnimationWrapperIcons>
              </Button>
            </Tooltip>
          )}
        </IconContainer>
        {/* <ProfileFlyout onElementClick={closeFlyout} isOpen={isProfileFlyoutOpen} /> */}
        <ProfileFlyoutWrapper {...getMenuProps()} isOpen={isOpen}>
          {show && (
            <NavItem
              leftIconName="catalogue"
              href={orderHistoryUrl}
              highlighted={highlightedIndex === 0}
              tabIndex={-1}
              {...getItemProps({ index: 0 })}
            >
              <Translation id="web20_ciam_order_history" />
            </NavItem>
          )}
          <NavItem
            leftIconName="profile"
            href={ciamProfileUrl ?? ''}
            highlighted={highlightedIndex === 1 - (show ? 0 : 1)}
            tabIndex={-1}
            {...getItemProps({ index: 1 - (show ? 0 : 1) })}
          >
            <Translation id="web20_ciam_geberit_profile" />
          </NavItem>
          <HorizontalMarginWrapper>
            <Divider />
          </HorizontalMarginWrapper>
          <NavButton
            leftIconName="logout"
            onClick={() => {
              logout();
            }}
            tabIndex={-1}
            highlighted={highlightedIndex === 2 - (show ? 0 : 1)}
            {...getItemProps({ index: 2 - (show ? 0 : 1) })}
          >
            <Translation id="web20_ciam_logout" />
          </NavButton>
        </ProfileFlyoutWrapper>
      </ProfileContainer>
    )
  );
}
