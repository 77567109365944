// utils
import { usePage } from 'utils/use-page';
import { useDesktopFlyoutOpen } from '../mega-menu-provider';

export function useShowFirstLevelIndicator() {
  const page = usePage();
  const { isDesktopFlyoutOpen } = useDesktopFlyoutOpen();

  if (isDesktopFlyoutOpen) return true;
  if (!page?.type) return false;

  const excludedPageTypesForIndicator = ['homepage', 'search_results'];
  return !excludedPageTypesForIndicator.includes(page.type);
}
