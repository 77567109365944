import { useSelector } from 'react-redux';

// utils
import { currentPageSelector } from './selectors/pageSelectors';

export function usePage() {
  const page = useSelector(currentPageSelector);

  return page;
}
